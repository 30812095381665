import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
import zhHans from 'vuetify/es5/locale/zh-Hans'
export default new Vuetify({
    theme: { dark: false },
    lang: {
        locales: { zhHans },
        current: 'zhHans',
      },
});
