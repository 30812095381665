<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-img
          src="../assets/img/cover.png"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col cols="6" sm="4" md="3" lg="2" class="text-center">
        <v-btn @click="() => $router.push('/porn')">ポルノ画像検出</v-btn>
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="2" class="text-center">
        <v-btn @click="() => $router.push('/segport')">肖像抽出</v-btn>
      </v-col>
      <!-- イメージ翻訳 -->
      <v-col cols="6" sm="4" md="3" lg="2" class="text-center">
        <v-btn @click="() => $router.push('/imgtrans')">イメージ翻訳</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelloWorld',

  data: () => ({}),
}
</script>
