<template>
  <v-app>
    <v-app-bar class="v-bar--underline v-bar--color" flat dense app>
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="https://www.e-business.co.jp/img/logo-dark.png"
            transition="scale-transition"
            width="200"
          />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <v-btn href="https://www.e-business.co.jp" target="_blank" text>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";

export default {
  name: "App",
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("controlling", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  mounted() {
    this.initDarkMode();
  },
  methods: {
    initDarkMode() {
      const darkMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
      if (darkMediaQuery) {
        darkMediaQuery.addEventListener("change", () => {
          this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        });
        if (darkMediaQuery.matches) {
          console.log("change default light to dark theme");
          // need to set 0 sec timeout to set the dark more after mounted event, due to some bug in the framework
          // setTimeout(() => (this.$vuetify.theme.dark = true), 0);
          Vue.nextTick().then(() => {
            this.$vuetify.theme.dark = true;
          });
        }
      }
    },
  },

  data: () => ({
    refreshing: false,
  }),
};
</script>
<style>
.v-app-bar {
  top: env(safe-area-inset-top) !important;
}
body {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
.theme--dark .v-bar--color {
  background-color: #000 !important;
  border-color: #000 !important;
}
.theme--light .v-bar--color {
  background-color: #fff !important;
  border-color: #fff !important;
}
.theme--dark .v-bar--underline,
.theme--light .v-bar--underline {
  border-width: 0 0 thin;
  border-style: solid;
}
.theme--dark .v-bar--underline.theme--light,
.theme--light .v-bar--underline.theme--light {
  border-bottom-color: rgba(0, 0, 0, 0.12) !important;
}
.theme--dark .v-bar--underline.theme--dark,
.theme--light .v-bar--underline.theme--dark {
  border-bottom-color: hsla(0, 0%, 100%, 0.12) !important;
}
</style>